import { styled } from '@mui/system';
import { Box, Grid, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { useCallback, useState } from 'react';

import AuthService from '../services/auth-service';

import teaser from '../teaser.png';
import APIErrorNotification from '../components/APIErrorNotification';
import useAPIError from '../common/usaAPIError';


const textStyle = {
  background: 'white',
  width: 'calc(100% - 16px)'
}

const LoginTitle = styled('h1')({
  marginBottom: 0
});

const TeaserImage = styled('img')({
  height: 812,
  marginLeft: -266
})


function Login() {
  const { addError } = useAPIError();

  const [email, setEmail] = useState('');
  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);

  const [password, setPassword] = useState('');
  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);

  const onLogin = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    (async () => {
      // prevent the form from doing it's own request
      e.preventDefault();
      try {
        await AuthService.login(email, password);
        // usenavigate doesn't seem to work ...
        window.location.replace("/");
      } catch (err: any) {
        if (err.detail) {
          addError(err.detail, 'error');
        } else {
          addError('Failed to log in', 'error');
        }
      }
    })();
  }, [email, password, addError]);

  return (
    <>
      <Grid container sx={{ height: '100%', 'overflow': 'hidden' }}>
        <Grid item xs={12} md={6}>
          <Box sx={{
            width: 'calc(100% - 16px)',
            maxWidth: '500px',
            marginLeft: '8px',
            marginRight: '8px',
            position: 'relative',
            top: '50%',
            left: '50%',
            msTransform: 'translate(-50%, -50%)',
            transform: 'translate(-50%, -50%)'
          }}>
            <LoginTitle>Eye Analyzer</LoginTitle>
            <Box sx={{
              fontWeight: 'regular',
              color: "#bbbbbb",
              marginBottom: '16px'
            }}>
              Please log in to your account.
            </Box>
              <form onSubmit={onLogin} >
                <TextField
                  required
                  id="username"
                  sx={textStyle}
                  onChange={handleEmail}
                  label="Email"
                  variant="outlined"
                  margin="dense"
                />
                <TextField
                  required
                  id="password"
                  sx={textStyle}
                  onChange={handlePassword}
                  label="Password"
                  variant="outlined"
                  type="password"
                  margin="dense"
                />
                <Button type="submit" variant="outlined" size="large" sx={{
                  marginTop: '16px'
                }}>
                  Log in
                </Button>
            </form>
          </Box>
        </Grid>
        <Grid item xs={0} md={6} sx={{
          display: { xs: "none", md: "block" }
        }}>
          <TeaserImage src={teaser} />
        </Grid>
      </Grid>
      <APIErrorNotification />
    </>
  );
}

export default Login;
