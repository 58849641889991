import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Button, Grid, List, ListItem, ListItemButton, ListItemText, Popover, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LAYOUT_MAX_WIDTH } from "../common/const";
import useAPIError from "../common/usaAPIError";
import AuthImage from "../components/AuthImage";
import BiometryReport from "../components/Biometry";
import Item from "../components/Item";
import { usePatientContext } from "../components/Layout";
import PatientInfo from "../components/PatientInfo";
import BiometryService, { Biometry } from "../services/biometry-service";
import { eyeSideName } from "../services/helper";
import PatientService from "../services/patient-service";
import ScanService, { ScanMetadata } from "../services/scan-service";


const Title = styled('h1')({
  marginTop: 0,
});


function Patient() {
  const params = useParams();
  const { addError } = useAPIError();
  const [patient, setPatient] = usePatientContext();
  const [patientScans, setPatientScans] = useState<ScanMetadata[]>();
  const [patientBiometry, setPatientBiometry] = useState<[Biometry?, Biometry?]>();

  const [leftCornea, setLeftCornea] = useState<ScanMetadata>();
  const [rightCornea, setRightCornea] = useState<ScanMetadata>();

  const [loading, setLoading] = useState(true);

  const setCorneaScans = (scans: ScanMetadata[]) => {
    const left = scans.find(scan => scan.scan_info.eye === 'left');
    const right = scans.find(scan => scan.scan_info.eye === 'right');

    if (left) {
      setLeftCornea(left);
    }
    if (right) {
      setRightCornea(right);
    }
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {

        // get all scans of this patient
        if (params.patientId) {
          const patientResponse = await PatientService.getPatientById(params.patientId);
          setPatient(patientResponse);

          const patientScanResponse = await ScanService.getScansByPatientId(params.patientId);
          setPatientScans(patientScanResponse);
          setCorneaScans(patientScanResponse);

          const patientBiometryResponse = await ScanService.getBiometryByPatientId(params.patientId);
          if (patientBiometryResponse.length > 0) {
            const patientBiometry = await BiometryService.getBiometryById(patientBiometryResponse[0].scan_info.id);
            setPatientBiometry(patientBiometry);
          }
        }

      } catch (err: any) {
        if (err.detail) {
          addError(err.detail, 'error');
        } else {
          addError('Failed to load patient info', 'error');
        }
      }
      setLoading(false);
    })();
  }, [params, addError, setPatient])


  const CorneaScanChooser = ({ children, side, scans }: { children: React.ReactNode, side: string, scans: ScanMetadata[] }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);

    return (
      <>
        <Button
          onClick={handleClick}
          sx={{ float: 'right', marginRight: 4 }}
          endIcon={<KeyboardArrowDown />}
        >
          {children}
        </Button>
        <Popover
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <List sx={{ width: 225 }}>
            {scans.map(s => (
              <ListItem disablePadding key={s.scan_info.id}>
                <ListItemButton>
                  <ListItemText primary={s.scan_info.timestamp.toISOString().slice(0, -8).replace('T', ' ')} />
                </ListItemButton>
              </ListItem>))
            }
          </List>
        </Popover>
      </>
    )
  }

  const EyeSumary = ({ side, biometry, cornea }: { side: string, biometry?: Biometry, cornea?: ScanMetadata }) => (
    <>
      <Title>{eyeSideName(side)} <Typography component={'span'} sx={{
        fontWeight: 'light',
        textTransform: 'capitalize',
        fontSize: '0.9em'
      }}>{side} Eye</Typography></Title>

      <Typography variant="h5" sx={{ marginBottom: 1 }}>
        Biometry
      </Typography>
      <BiometryReport biometry={biometry} />
      {(cornea && patientScans) &&
        <>
          <Typography variant="h5" sx={{ marginBottom: 1, marginTop: 4 }}>
            Cornea

            <CorneaScanChooser scans={patientScans.filter(s => s.scan_info.eye === side)} side={side}>
              <Box sx={{ float: 'right', fontWeight: 300, marginRight: 2, fontSize: 20 }}>{cornea.scan_info.timestamp.toISOString().slice(0, -8).replace('T', ' ')}</Box>
            </CorneaScanChooser>
          </Typography>
          <Typography variant="h5" sx={{ marginTop: 2, marginBottom: 1, fontSize: 20 }}>
            Axial Refractive <Box sx={{ display: 'inline', fontWeight: 'light' }}>[D]</Box>
          </Typography>
          <Item variant="outlined" sx={{ minHeight: '400px' }}>
            <Grid container spacing={1}>
              <Grid item md={6} xs={12}>
                <AuthImage src={`/api/scans/${cornea.scan_info.id}/axial_anterior`} width="100%" skeletonHeight="350px" load={!loading} />
                <Typography sx={{ paddingLeft: 4 }}>Axial Anterior</Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <AuthImage src={`/api/scans/${cornea.scan_info.id}/axial_posterior`} width="100%" skeletonHeight="350px" load={!loading} />
                <Typography sx={{ paddingLeft: 4 }}>Axial Posterior</Typography>
              </Grid>
            </Grid>
          </Item>

        </>
      }
    </>
  )

  return (
    <>
      <PatientInfo patient={patient} loading={loading} />

      {!loading &&
        (
          <Grid container spacing={1} sx={{ maxWidth: LAYOUT_MAX_WIDTH * 2 }}>
            <Grid item xs={12} xl={6}>
              <EyeSumary side="right" biometry={patientBiometry?.at(1)} cornea={rightCornea} />
            </Grid>
            <Grid item xs={12} xl={6}>
              <EyeSumary side="left" biometry={patientBiometry?.at(0)} cornea={leftCornea} />
            </Grid>
          </Grid>
        )
      }
    </>
  )
}

export default Patient;
