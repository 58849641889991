export class HTTPValidationError extends Error {
  public detail: string = "";

  public constructor() {
    super();
  }
}

export type Patient = {
  id: string;
  patient_id: string;
  firstname: string;
  lastname: string;
};

export type PaginationAPIResponse<T> = {
  response: T;
  page: number;
  per_page: number;
  count: number;
}

// https://stackoverflow.com/a/14509447
export const dateTimeReviver = (key: string, value: any) => {
  if (typeof value === 'string' && /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d/.exec(value)) {
    return new Date(value);
  }
  return value;
}

export const eyeSideName = (eye: string) => {
  if (eye === 'right') {
    return 'OD';
  } else if (eye === 'left') {
    return 'OS';
  } else {
    return 'Unknown';
  }
}
