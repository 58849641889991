import { Skeleton, Typography } from "@mui/material";
import { Patient } from "../services/helper";

const PatientInfo = ({ patient, loading }: { patient?: Patient, loading: boolean }) => (
  <>
    {(patient && !loading) ?
      <>
        <h1>{patient.firstname} {patient.lastname} <Typography component={'span'} sx={{
          fontWeight: 'lighter',
          textTransform: 'capitalize',
          fontSize: '0.9em'
        }}></Typography></h1>
        <Typography sx={{
          marginTop: -3,
          marginBottom: 4,
          color: 'darkgrey'
        }}>Date of Birth TODO</Typography>
      </> :
      <>
        <Skeleton variant="text" width="80%" sx={{ fontSize: '2.5em', marginTop: '20px' }} />
        <Skeleton variant="text" width="60%" sx={{ fontSize: '1em', marginTop: '-10px' }} />
      </>
    }
  </>
)

PatientInfo.defaultProps = {
  loading: false
}


export default PatientInfo
