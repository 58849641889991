import { Box, Typography } from "@mui/material";
import { PredictionReport } from "../services/scan-service";
import HtmlTooltip from '@mui/material/Tooltip';
import React from "react";
import { InfoOutlined } from "@mui/icons-material";



type ScanReportProps = {
  report: PredictionReport;
};

function ScanReportSummary(props: ScanReportProps) {
  const confidence = props.report.predictions[props.report.prediction_index];
  let predictionColor = 'warning.dark';
  if (confidence > 0.30) {
    predictionColor = 'info.dark';
  }

  if (confidence > 0.60) {
    predictionColor = 'success.dark';
  }

  return (
    <>
      <Box sx={{ color: 'text.secondary' }}>
        Prediction

        <HtmlTooltip
          placement="right"
          title={
            <React.Fragment>
              <Typography color="inherit">CorNet Disease Prediction</Typography>

              CorNet is an artificial neral network wich predicts certain cornea diseases.
              This is no final diagnosis but should help to make diagnostic decisions.
            </React.Fragment>
          }
        >
          <InfoOutlined fontSize="small" sx={{ ml: 'auto', color: 'grey.500', float: 'right' }} />
        </HtmlTooltip>
      </Box>
      <Box sx={{ color: 'text.primary', fontSize: 30, fontWeight: 'medium' }}>
        {props.report.labels[props.report.prediction_index]}
      </Box>
      <Box
        sx={{
          color: predictionColor,
          display: 'inline',
          fontWeight: 'bold',
          mx: 0.5,
          fontSize: 18,
        }}
      >
        {(props.report.predictions[props.report.prediction_index] * 100).toFixed(2)} %
      </Box>
      <Box sx={{ color: 'text.secondary', display: 'inline', fontSize: 18 }}>
        Confidence
      </Box>
    </>
  );
}

export default ScanReportSummary;