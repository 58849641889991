
import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, styled } from '@mui/material';
import { useCallback, useState } from 'react';
import { useDropzone, FileWithPath } from 'react-dropzone';
import useAPIError from '../common/usaAPIError';
import APIErrorNotification from '../components/APIErrorNotification';
import { HTTPValidationError } from '../services/helper';
import ScanService, { Device } from '../services/scan-service';
import { LAYOUT_MAX_WIDTH } from '../common/const';


const DropZone = styled('div')({
  border: '1px dashed #757575',
  borderRadius: '2px',
  padding: '40px'
})

const devices = [
  Device.casia2,
  Device.eyestar900,
  Device.iolmaster700,
  Device.ms39,
  Device.pentacam,
]


function Upload() {
  const { addError } = useAPIError();

  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [filesError, setFilesError] = useState<boolean>(false);

  const [device, setDevice] = useState<Device | undefined>(undefined);
  const [deviceError, setDeviceError] = useState<boolean>(false);

  const [uploading, setUploading] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'text/csv': [],
      'text/xml': [],
      'application/pdf': [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles.map((file) => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });
  const thumbs = files.map((file: FileWithPath) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const upploadFiles = useCallback(() => {
    (async () => {
      try {
        if (device === undefined) {
          setDeviceError(true);
          return;
        }

        if (files.length === 0) {
          setFilesError(true);
          return;
        }

        setUploading(true);
        await ScanService.uploadScans(files, device);
        setFiles([]);
        setUploading(false);
        addError('All scans successfully uploaded!', 'info');
      } catch (err) {
        if ((err as HTTPValidationError).detail) {
          addError(`Could not upload scan. ${(err as HTTPValidationError).detail}`, "error");
        } else {
          addError('Could not upload scan.', "error");
        }
        setUploading(false);
      }
    })();
  }, [files, addError, setUploading, setFiles, setDeviceError, setFilesError, device]);

  const handleChange = useCallback((e: SelectChangeEvent<Device>) => {
    setDeviceError(false)
    setDevice(e.target.value as Device)
  }, [setDeviceError, setDevice])

  return (
    <Box sx={{ maxWidth: LAYOUT_MAX_WIDTH }}>
      <FormControl sx={{ marginBottom: 2, marginTop: 2 }}>
        <InputLabel id="select-device-label" sx={{ paddingRight: 4 }}>Device</InputLabel>
        <Select
          labelId="select-device-label"
          id="select-device"
          required
          sx={{
            width: '250px'
          }}
          value={device}
          label="Device"
          onChange={handleChange}
          {...{ disabled: uploading, error: deviceError }}
        >
          {devices.map(d => (<MenuItem key={d} value={d}>{d.toString()}</MenuItem>))}
        </Select>
      </FormControl>
      <section className="container">
        <DropZone {...getRootProps({ className: 'dropzone' })} {...{ disabled: uploading }} sx={{ borderColor: filesError ? 'rgb(211, 47, 47)' : '#757575' }}>
          <input {...getInputProps()} type="file" />
          <p>Drag 'n' drop some files here, or click to select cornea scans</p>
        </DropZone>
      </section>
      <aside>
        {uploading ? <CircularProgress size="24px" /> : (
          <>
            {files.length > 0 && <h4>Files to Upload</h4>}
            <ul>{thumbs}</ul>
          </>
        )}
      </aside>
      <Button
        variant="outlined"
        component="label"
        {...{ disabled: uploading }}
        onClick={() => upploadFiles()}
      >Upload</Button>
      <APIErrorNotification />
    </Box>
  );
}

export default Upload;
