import AuthService from "./auth-service";
import { dateTimeReviver, HTTPValidationError, PaginationAPIResponse, Patient } from "./helper";
import { ScanInfo } from "./scan-service";


export type PatientResponse = {
  patient: Patient;
  scans: ScanInfo[];
}


const getPatients = async (q: string = "", page: number = 1, perPage: number = 10): Promise<PaginationAPIResponse<PatientResponse[]>> => {
  const response = await fetch('/api/patients?' + new URLSearchParams({
    q,
    page: page.toString(),
    per_page: perPage.toString()
  }), {
    headers: AuthService.authHeader()
  });

  if (!response.ok) {
    throw Object.assign(new HTTPValidationError(), JSON.parse(await response.text(), dateTimeReviver));
  }

  return JSON.parse(await response.text(), dateTimeReviver) as PaginationAPIResponse<PatientResponse[]>;
};

const getPatientById = async (patientId: string): Promise<Patient> => {
  const response = await fetch(`/api/patients/${patientId}`, {
    headers: AuthService.authHeader()
  });

  if (!response.ok) {
    throw Object.assign(new HTTPValidationError(), JSON.parse(await response.text(), dateTimeReviver));
  }

  return JSON.parse(await response.text(), dateTimeReviver) as Patient;
};

const PatientService = {
  getPatients,
  getPatientById,
};

export default PatientService;
