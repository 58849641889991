import { Button, CircularProgress, Grid, Typography, Box, Slider, Alert, AlertTitle, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAPIError from "../common/usaAPIError";
import Item from "../components/Item";
import APIErrorNotification from "../components/APIErrorNotification";
import AuthImage from "../components/AuthImage";
import ScanReport from "../components/ScanReport";
import AsymmetryScore from '../components/AsymmetryScore';
import ScanService, { ProgressionAmount, ProgressionReport, ProgressionStage, ScanDiagnosis, ScanMetadata } from "../services/scan-service";
import ScanReportSummary from "../components/ScanReportSummary";


type SliderMarkValues = {
  value: number;
  label: React.ReactNode;
}

function Progression() {
  const params = useParams();
  const { addError } = useAPIError();
  const [scan1, setScan1] = useState<ScanDiagnosis>();
  const [scan2, setScan2] = useState<ScanDiagnosis>();
  const [value, setValue] = useState<number[]>([]);
  const [nextProgression, setNextProgression] = useState<[string, string]>(['', '']);
  const [patientInfo, setPatientInfo] = useState<ScanMetadata[]>();
  const [marks, setMarks] = useState<SliderMarkValues[]>();

  const [scanId1, scanId2] = params.scans?.split("..") || ['', ''];

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
    setNextProgression([
      patientInfo?.at((newValue as number[]).at(0) || 0)?.scan_info.id || '',
      patientInfo?.at((newValue as number[]).at(-1) || 0)?.scan_info.id || ''
    ]);
  };

  type ProgressionStageGroup = {
    [key in ProgressionStage]: [string, ProgressionAmount][]
  }
  const [progressionStageGroup, setProgressionStageGroup] = useState<ProgressionStageGroup>();
  const [progression, setProgression] = useState<ProgressionReport>();

  const [loading, setLoading] = useState(true);


  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        if (!scanId1 || !scanId2) {
          throw new Error('Invalid comparison url. Expected `/progression/:scanId1..:scanId2`');
        }

        const scan1Response = await ScanService.getScan(scanId1);
        setScan1(scan1Response);

        const scan2Response = await ScanService.getScan(scanId2);
        setScan2(scan2Response);

        // get all scans of this patient
        // reverse because the api returns descending dates
        const patientScansForCurrentEye = (await ScanService.getScansByPatientId(scan1Response.patient.id))
          .filter((s) => s.scan_info.eye === scan1Response.scan_info.eye).reverse();

        setPatientInfo(patientScansForCurrentEye);
        const markValues = patientScansForCurrentEye.map((s, i) => (
          {
            value: i,
            label: (
              <Typography
                fontWeight={200}
                fontSize="1em">
                {s.scan_info.timestamp.toISOString().slice(0, -8).replace('T', ' ')}
              </Typography>
            )} as SliderMarkValues
        ));
        setMarks(markValues);

        const nextValue = [
          patientScansForCurrentEye.findIndex((e) => e.scan_info.id === scan1Response.scan_info.id),
          patientScansForCurrentEye.findIndex((e) => e.scan_info.id === scan2Response.scan_info.id)
        ];
        setValue(nextValue);
        setNextProgression([
          patientScansForCurrentEye.at(nextValue.at(0) || 0)?.scan_info.id || '',
          patientScansForCurrentEye.at(nextValue.at(-1) || 0)?.scan_info.id || ''
        ]);

        // get this scans details
        const progression = await ScanService.getProgression(scanId1, scanId2);
        setProgression(progression);

        const groupedProgression = Object.entries(progression.treatment_progression)
          .filter((p): p is [string, ProgressionAmount] => (p as [string, ProgressionAmount])[1].amount !== undefined)
          .reduce((group: ProgressionStageGroup, p) => {
            const stage = p[1].stage;
            group[stage] = group[stage] ?? [];
            group[stage].push(p);
            return group;
          }, {} as ProgressionStageGroup);

        setProgressionStageGroup(groupedProgression);

      } catch (err: any) {
        if (err.detail) {
          addError(err.detail, 'error');
        } else if (err.message) {
          addError(err.message, 'error');
        } else {
          addError('Failed to load scan', 'error');
        }
      }
      setLoading(false);
    })();
  }, [scanId1, scanId2, addError, setScan1, setScan2, setMarks, setValue, setNextProgression, setPatientInfo, setProgressionStageGroup]);

  const timeDuration = (timestamp1: Date, timestamp2: Date): string => {
    const seconds = timestamp2.getTime() / 1000 - timestamp1.getTime() / 1000;
    const years = Math.floor(seconds / 31536000);
    const days = Math.floor((seconds % 31536000) / 86400);

    if (years === 0 && days === 0) {
      return "Same Day"
    }

    let duration = ''
    if (years > 0) {
      duration += `${years} Year${years > 1 ? 's' : ''} `
    }
    if (days > 0) {
      duration += `${years > 0 ? ' and' : ''}${days} Day${days > 1 ? 's' : ''}`
    }
    return duration
  }


  const formatProgressionWarning = (progressionGroup: ProgressionStageGroup): React.ReactNode => {
    const getUnit = (name: string): React.ReactNode => {
      return <Typography component="span" fontWeight={100} fontSize="1em">{name.includes('axial') ? '[D]' : '[μm]'}</Typography>
    }

    const group = progressionGroup[2] ? progressionGroup[2] : progressionGroup[1]
    if (group) {
      return (
        <ul>
          { group.map((p) => (
              <li>
                {p[0].split('_').map((w) =>
                  w[0].toUpperCase() + w.substring(1)
                ).join(' ')}: <strong>{p[1].amount > 0 ? '+' : ''}{p[1].amount.toFixed(2)}</strong> {getUnit(p[0])}
              </li>
            ))
          }
        </ul>
      )
    }

    return <></>
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          { marks && scan1 && !loading ?

            <h1>{scan1?.patient.firstname} {scan1?.patient.lastname} <Typography component={'span'} sx={{
              fontWeight: 'lighter',
              textTransform: 'capitalize',
              fontSize: '0.9em'
            }}>{scan1?.scan_info.eye} Eye</Typography></h1> :

            <>
              <Box sx={{ display: 'flex' }}>
                <Skeleton variant="text" width="40%" sx={{ fontSize: '2.5em', marginTop: '20px' }} />
                <Skeleton variant="text" width="20%" sx={{ fontSize: '2.5em', marginLeft: '10px', marginTop: '20px' }} />
              </Box>
            </>
          }
        </Grid>
        <Grid item xs={12} lg={9}>
          {marks && scan1 && !loading ? <Slider
            value={value}
            getAriaLabel={() => 'Scan Comparison'}
            onChange={handleChange}
            step={null}
            valueLabelDisplay="off"
            marks={marks}
            min={0}
            max={Math.max(...marks.map((m) => m.value))}
            {...(marks.length < 3 && { disabled: true })}
          /> : <Skeleton variant="text" width="100%" sx={{ fontSize: '1em', marginTop: '18px', marginBottom: '25px' }} />}
        </Grid>
        <Grid item lg={1} xs={0}>
          {/* spacer */}
        </Grid>
        <Grid item xs={12} lg={2}>
          {nextProgression && <Button
            fullWidth
            href={`/progression/${nextProgression[0]}..${nextProgression[1]}`}
            sx={{
              marginBottom: '8px'
            }}
          >Progression →</Button>}
        </Grid>
        <Grid item xs={12} lg={6}>
          { scan1 && scan2 && progression && !loading ?
            <Item variant="outlined" sx={{minHeight: '200px'}} >
              <Typography sx={{
                fontSize: '1.2em',
                marginBottom: 2,
                marginTop: '4px',
                float: 'left'
              }}>Examination <Typography component="span" fontWeight={200} fontSize="1em">{scan1?.scan_info.timestamp.toISOString().slice(0, -8).replace('T', ' ')}</Typography></Typography>
              {scan1 && <Button sx={{float: 'right'}} href={`/scan/${scan1?.scan_info.id}`}>Details →</Button>}
              {progression ? <ScanReport report={progression.scan_1} /> : <CircularProgress size="24px" />}
            </Item> : <Skeleton variant="rectangular" height="198px" sx={{borderRadius: '2px'}} />
          }
        </Grid>
        <Grid item xs={12} lg={6}>
        { scan1 && scan2 && progression && !loading ?
          <Item variant="outlined" sx={{minHeight: '200px'}} >
            <Typography sx={{
              fontSize: '1.2em',
              marginBottom: 2,
              marginTop: '4px',
              float: 'left'
            }}>Examination <Typography component="span" fontWeight={200} fontSize="1em">{scan2?.scan_info.timestamp.toISOString().slice(0, -8).replace('T', ' ')}</Typography></Typography>
            {scan2 && <Button sx={{float: 'right'}} href={`/scan/${scan2?.scan_info.id}`}>Details →</Button>}
            {progression ? <ScanReport report={progression.scan_2} /> : <CircularProgress size="24px" />}
          </Item> : <Skeleton variant="rectangular" height="198px" sx={{borderRadius: '2px'}} />
        }
        </Grid>
        <Grid item xs={12}>
          { (scan1 && scan2 && !loading) ?
            <Typography sx={{
              fontSize: '1.4em',
              marginTop: 2,
              float: 'left'
            }}> Progression <Typography component="span" fontWeight={200} fontSize="1em">
                {scan1.scan_info.timestamp.toISOString().slice(0, -8).replace('T', ' ')} - {scan2.scan_info.timestamp.toISOString().slice(0, -8).replace('T', ' ')} ({timeDuration(scan1.scan_info.timestamp, scan2.scan_info.timestamp)})
              </Typography>
            </Typography> : <Skeleton variant="text" width="60%" sx={{ fontSize: '2em', marginTop: 1 }} />
          }
        </Grid>
        <Grid item xs={12}>
          { (scan1 && scan2 && progression && !loading && progressionStageGroup && (progressionStageGroup[1] || progressionStageGroup[2])) ?
            <Alert severity={progressionStageGroup[1] ? 'warning' : 'error'} sx={{marginTop: 1, marginBottom: 1, width: '100%'}}>
              <AlertTitle>
                Progression Warning
              </AlertTitle>
              There was significant progression over a timespan of <strong>{timeDuration(scan1.scan_info.timestamp, scan2.scan_info.timestamp)}</strong> {formatProgressionWarning(progressionStageGroup)}
            </Alert> : <></>
          }
        </Grid>
        <Grid item xs={12} lg={2}>
          { progression && !loading ?
            <Item variant="outlined" sx={{height: '100%', p: 2}}>
              {progression ? <ScanReportSummary report={progression.scan_2} /> : <CircularProgress size="24px" />}
            </Item> : <Skeleton variant="rectangular" height="198px" sx={{borderRadius: '2px'}} />
          }
        </Grid>
        <Grid item xs={12} lg={4}>
          { progression && !loading ?
            <Item variant="outlined" sx={{height: '100%', p: 2}}>
              {progression ? <AsymmetryScore progression score={progression.asymmetry_progression} /> : <CircularProgress size="24px" /> }
            </Item>: <Skeleton variant="rectangular" height="198px" sx={{borderRadius: '2px'}} />
          }
        </Grid>
        <Grid item xs={12} lg={6}>
          { progression && !loading ?
            <Item variant="outlined" sx={{width: '100%', height: '100%'}}>
              {progression ? <ScanReport report={progression.progression} progression={true} /> : <CircularProgress size="24px" />}
            </Item> : <Skeleton variant="rectangular" height="198px" sx={{borderRadius: '2px'}} />
          }
        </Grid>

        { [
            {name: 'Axial Anterior', id: 'axial_anterior', unit: 'D'},
            {name: 'Axial Posterior', id: 'axial_posterior', unit: 'D'},
            {name: 'Elevation Anterior', id: 'elevation_anterior', unit: 'μm'},
            {name: 'Elevation Posterior', id: 'elevation_posterior', unit: 'μm'},
            {name: 'Pachymetry', id: 'pachymetry', unit: 'μm'}
          ].map((feature) =>(
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ marginLeft: 1, marginTop: 2, marginBottom: 1 }}>
            {feature.name} <Box sx={{ display: 'inline', fontWeight: 'light' }}>[{feature.unit}]</Box>
            </Typography>
            <Item variant="outlined" sx={{ minHeight: '328px' }}>
              <Grid container spacing={1}>
                <Grid item lg={4} xs={12}>
                  <AuthImage src={`/api/scans/${scanId1}/${feature.id}`} width="100%" skeletonHeight="293px" load={!loading} />
                  <Typography sx={{ paddingLeft: 4 }}>{scan1?.scan_info.timestamp.toISOString().slice(0, -8).replace('T', ' ')}</Typography>
                </Grid>
                <Grid item lg={4} xs={12}>
                  <AuthImage src={`/api/scans/progression/${scanId1}..${scanId2}/${feature.id}`} width="100%" skeletonHeight="293px" load={!loading} />
                  <Typography sx={{ paddingLeft: 4 }}>Progression</Typography>
                </Grid>
                <Grid item lg={4} xs={12}>
                  <AuthImage src={`/api/scans/${scanId2}/${feature.id}`} width="100%" skeletonHeight="293px" load={!loading} />
                  <Typography sx={{ paddingLeft: 4 }}>{scan2?.scan_info.timestamp.toISOString().slice(0, -8).replace('T', ' ')}</Typography>
                </Grid>
              </Grid>
            </Item>
          </Grid>
        )) }
      </Grid>

      <APIErrorNotification />
    </>
  );
}

export default Progression;
