import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Link,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";

import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { useParams } from 'react-router-dom';

import { useCallback, useEffect, useState } from "react";
import AsymmetryScore from '../components/AsymmetryScore';
import AuthImage from '../components/AuthImage';
import Item from "../components/Item";
import KeratoconusABCDScore from '../components/KeratoconusABCDScore';
import ScanService, { PredictionReport, ScanDiagnosis, ScanMetadata } from "../services/scan-service";

import { LAYOUT_MAX_WIDTH } from "../common/const";
import useAPIError from "../common/usaAPIError";
import APIErrorNotification from "../components/APIErrorNotification";
import { usePatientContext } from "../components/Layout";
import ScanList from "../components/ScanList";
import ScanReport from "../components/ScanReport";
import ScanReportSummary from "../components/ScanReportSummary";


function Scan() {
  const params = useParams();
  const { addError } = useAPIError();
  const [scan, setScan] = useState<ScanDiagnosis>();
  const [report, setReport] = useState<PredictionReport>();
  const [, setPatient] = usePatientContext();
  const [patientScans, setPatientScans] = useState<ScanMetadata[]>();
  const [patientScansCurrentEye, setPatientScansCurrentEye] = useState<ScanMetadata[]>();

  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loading, setLoading] = useState(true);

  const downloadKeratoconusReport = useCallback(() => {
    (async () => {
      setLoadingPdf(true);
      if (params.scanId) {
        await ScanService.downloadKeratoconusReport(params.scanId);
      }
      setLoadingPdf(false);
    })();
  }, [params]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        // first set all data to 'undefined' so that
        // the UI doesn't show a mix of old and new data
        setScan(undefined);
        setReport(undefined);
        setPatientScansCurrentEye(undefined);

        // get this scans details
        const scanResponse = await ScanService.getScan(params.scanId || '');
        setScan(scanResponse);

        setPatient(scanResponse.patient);

        // get all scans of this patient
        const patientScanResponse = await ScanService.getScansByPatientId(scanResponse.patient.id);
        setPatientScans(patientScanResponse);
        setPatientScansCurrentEye(patientScanResponse.filter((s) => s.scan_info.eye === scanResponse.scan_info.eye));

        const scanReport = await ScanService.getReport(params.scanId || '');
        setReport(scanReport);
      } catch (err: any) {
        if (err.detail) {
          addError(err.detail, 'error');
        } else {
          addError('Failed to load scan', 'error');
        }
      }
      setLoading(false);
    })();
  }, [params, addError, setPatient]);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        {scan ? <Link
          underline="hover"
          color="inherit"
          href={`/patient/${scan.patient.id}`}
        >
          {scan.patient.firstname} {scan.patient.lastname}
        </Link> : <Skeleton variant="text" sx={{ fontSize: '1rem', width: 160 }} />
        }
        {scan ?
          <Typography color="text.secondary">{scan.scan_info.timestamp.toISOString()}</Typography> :
          <Skeleton variant="text" sx={{ fontSize: '1rem', width: 160 }} />
        }
      </Breadcrumbs>

      <Grid container spacing={1} sx={{ maxWidth: LAYOUT_MAX_WIDTH }}>
        <Grid item xs={12} lg={9} sx={{ minHeight: '130px' }}>
          {scan ?
            <>
              <h1>{scan.patient.firstname} {scan.patient.lastname} <Typography component={'span'} sx={{
                fontWeight: 'lighter',
                textTransform: 'capitalize',
                fontSize: '0.9em'
              }}>{scan?.scan_info.eye} Eye</Typography></h1>
              <Typography sx={{
                marginTop: -3,
                marginBottom: 4,
                color: 'darkgrey'
              }}>Examination time {scan.scan_info.timestamp.toISOString()}</Typography>
            </> :
            <>
              <Skeleton variant="text" width="80%" sx={{ fontSize: '2.5em', marginTop: '20px' }} />
              <Skeleton variant="text" width="60%" sx={{ fontSize: '1em', marginTop: '-10px' }} />
            </>
          }
          <Tooltip placement="left" title="Download Keratoconus Report" >
            <Button
              sx={{
                float: 'right',
                marginTop: scan ? '-86px' : '-58px',
                color: 'gray',
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent"
                }
              }}
              onClick={downloadKeratoconusReport}
            >
              {loadingPdf ? <CircularProgress size="24px" /> : <SimCardDownloadIcon />}
            </Button>
          </Tooltip>
        </Grid>

        <Grid item lg={9}>
          <Grid container spacing={1}>
            <Grid item md={4} xs={12}>
              {report && !loading ?
                <Item variant="outlined" sx={{ height: '100%', p: 2, minHeight: '140px' }}>
                  <ScanReportSummary report={report} />
                </Item> : <Skeleton variant="rectangular" height="146px" sx={{ borderRadius: '2px' }} />
              }
            </Grid>
            <Grid item md={8} xs={12}>
              {report && !loading ?
                <Item variant="outlined" sx={{ height: '100%', minHeight: '148px' }}>
                  {report ? <ScanReport report={report} /> : <CircularProgress size="24px" />}
                </Item> : <Skeleton variant="rectangular" height="146px" sx={{ borderRadius: '2px' }} />
              }
            </Grid>

            <Grid item md={6} xs={12}>
              {scan && !loading ?
                <Item variant="outlined" sx={{ p: 2, display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                  {scan ? <KeratoconusABCDScore score={scan.abcd_score} /> : <CircularProgress size="24px" />}
                </Item> : <Skeleton variant="rectangular" height="146px" sx={{ borderRadius: '2px' }} />
              }
            </Grid>
            <Grid item md={6} xs={12}>
              {scan && !loading ?
                <Item variant="outlined" sx={{ p: 2, display: 'flex', alignItems: 'flex-start', height: '100%', minHeight: '176px' }}>
                  {scan ? <AsymmetryScore score={scan.asymmetry_score} /> : <CircularProgress size="24px" />}
                </Item> : <Skeleton variant="rectangular" height="146px" sx={{ borderRadius: '2px' }} />
              }
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ marginLeft: 1, marginTop: 2, marginBottom: 1 }}>
                Axial Refractive <Box sx={{ display: 'inline', fontWeight: 'light' }}>[D]</Box>
              </Typography>
              <Item variant="outlined" sx={{ minHeight: '400px' }}>
                <Grid container spacing={1}>
                  <Grid item md={6} xs={12}>
                    <AuthImage src={`/api/scans/${params.scanId}/axial_anterior`} width="100%" skeletonHeight="350px" load={!loading} />
                    <Typography sx={{ paddingLeft: 4 }}>Axial Anterior</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <AuthImage src={`/api/scans/${params.scanId}/axial_posterior`} width="100%" skeletonHeight="350px" load={!loading} />
                    <Typography sx={{ paddingLeft: 4 }}>Axial Posterior</Typography>
                  </Grid>
                </Grid>
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ marginLeft: 1, marginTop: 2, marginBottom: 1 }}>
                Elevation <Box sx={{ display: 'inline', fontWeight: 'light' }}>[μm]</Box>
              </Typography>

              <Item variant="outlined" sx={{ minHeight: '400px' }}>
                <Grid container spacing={1}>
                  <Grid item md={6} xs={12}>
                    <AuthImage src={`/api/scans/${params.scanId}/elevation_anterior`} width="100%" skeletonHeight="350px" load={!loading} />
                    <Typography sx={{ paddingLeft: 4 }}>Elevation Anterior</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <AuthImage src={`/api/scans/${params.scanId}/elevation_posterior`} width="100%" skeletonHeight="350px" load={!loading} />
                    <Typography sx={{ paddingLeft: 4 }}>Elevation Posterior</Typography>
                  </Grid>
                </Grid>
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ marginLeft: 1, marginTop: 2, marginBottom: 1 }}>
                Pachymetry <Box sx={{ display: 'inline', fontWeight: 'light' }}>[μm]</Box>
              </Typography>

              <Item variant="outlined" sx={{ minHeight: '400px' }}>
                <Grid container spacing={1}>
                  <Grid item md={6} xs={12}>
                    <AuthImage src={`/api/scans/${params.scanId}/pachymetry`} width="100%" load={!loading} />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {/* <Box sx={{ marginLeft: 8 }}>
                      <AuthImage src={`/api/scans/${params.scanId}/pachymetry/boxplot`} height="350px" skeletonHeight="350px" load={!loading} />
                    </Box> */}
                  </Grid>
                </Grid>
              </Item>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={3} xs={12} >
          {(patientScansCurrentEye && patientScansCurrentEye.length > 1) ?
            <Button
              variant="outlined"
              fullWidth
              href={`/progression/${patientScansCurrentEye.at(-1)?.scan_info.id}..${patientScansCurrentEye.at(0)?.scan_info.id}`}
              sx={{
                marginBottom: '8px'
              }}
            >Progression →</Button> :
            <Tooltip placement="top" title="Upload at least one more scan of the current eye to display progression.">
              <span>
                <Button
                  variant="outlined"
                  fullWidth
                  disabled
                  sx={{
                    marginBottom: '8px'
                  }}>Progression →</Button>
              </span>
            </Tooltip>
          }
          <Item variant="outlined">
            {patientScans ?
              <ScanList
                selectedScanId={scan?.scan_info.id || ''}
                scans={patientScans}
              /> :
              <CircularProgress size="24px" />}
          </Item>
        </Grid>
      </Grid>
      <APIErrorNotification />
    </>
  );
}

export default Scan;
