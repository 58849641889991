import { createBrowserRouter, RouterProvider } from "react-router-dom";
import APIErrorProvider from "./common/APIErrorProvider";
import ProtectedRoute, { ProtectedRouteProps } from "./common/PrivateRoute";
import Layout from "./components/Layout";

import Dashboard from "./pages/Dashboard";
import IOLPLanner from "./pages/IOLPlanner";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Patient from "./pages/Patient";
import Progression from "./pages/Progression";
import Scan from "./pages/Scan";
import Upload from "./pages/Upload";
import AuthService from "./services/auth-service";


function App() {

  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
    isAuthenticated: AuthService.isAuthenticated(),
  };

  const layouted = (component: JSX.Element): JSX.Element => (
    <Layout>
      <ProtectedRoute {...defaultProtectedRouteProps} outlet={component} />
    </Layout >
  )

  const router = createBrowserRouter([
    {
      path: "/",
      element: layouted(<Dashboard />),
    },
    {
      path: "/scan/:scanId",
      element: layouted(<Scan />),
    },
    {
      path: "/progression/:scans",
      element: layouted(<Progression />),
    },
    {
      path: "/patient/:patientId",
      element: layouted(<Patient />),
    },
    {
      path: "/patient/:patientId/iol",
      element: layouted(<IOLPLanner />),
    },
    {
      path: "/upload",
      element: layouted(<Upload />),
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "*",
      element: <NotFound />,
    }
  ]);

  return (
    <APIErrorProvider>
      <RouterProvider router={router} />
    </APIErrorProvider>
  );
}

export default App;
