import { Alert, Snackbar } from "@mui/material";
import useAPIError from "../common/usaAPIError";

export default function APIErrorNotification() {
  const { error, removeError } = useAPIError();

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    removeError();
  };


  return (
    <Snackbar
      open={!!error}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert variant="outlined" onClose={handleClose} severity={(error && error.status && error.status) || "info"} sx={{ width: '100%', bgcolor: 'white' }}>
        {error && error.message && error.message}
      </Alert>
    </Snackbar>
  )
}