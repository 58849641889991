import { Box, Grid, SxProps, Typography } from "@mui/material";
import { useLayoutEffect, useRef, useState } from "react";
import eye from "../eye.svg";
import { Biometry, Keratometry } from "../services/biometry-service";
import Item from "./Item";

type BiometryReportProps = {
  biometry?: Biometry;
  fullKeratometry: boolean;
}

function BiometryReport({ biometry, fullKeratometry }: BiometryReportProps) {
  const eyeRef = useRef<HTMLImageElement>(null);
  const [eyeHeight, setEyeHeight] = useState(0);
  const [eyeWidth, setEyeWidth] = useState(0);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (eyeRef.current) {
        const height = eyeRef.current.offsetHeight;
        const width = eyeRef.current.offsetWidth;
        setEyeHeight(height);
        setEyeWidth(width);
      }
    }

    handleResize(); // initial call to get width and height of the element
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [eyeRef, setEyeHeight, setEyeWidth]);


  if (!biometry) {
    return (
      <Item variant="outlined" sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <Typography>No biometry available.</Typography>
      </Item>
    )
  }

  const EyeVisualization = ({ sx }: { sx?: SxProps }) => (
    <Box sx={{ position: 'relative', width: '100%', marginTop: 5, marginBottom: 5, ...sx }}>

      {/* FIXME: the layouting can probably be done better here ... */}

      <BiometryValue name="WtW" sx={{ position: 'absolute', top: -33, left: '20%' }}>
        <Box sx={{ width: 60, textAlign: 'right', fontWeight: 600 }}>
          {biometry.white_to_white.diameter.toFixed(2)}
        </Box>
        <Typography fontWeight={100} paddingLeft={1}>[mm]</Typography>
      </BiometryValue>

      {/* Left Sclera Line */}
      <Box sx={{ position: 'absolute', top: '0%', left: eyeWidth * 0.24, height: eyeHeight / 2, zIndex: 2, borderLeft: '2px dotted #2b2b2b' }} />
      {/* Right Sclera Line */}
      <Box sx={{ position: 'absolute', top: '0%', left: eyeWidth * 0.755, height: eyeHeight / 2, zIndex: 2, borderLeft: '2px dotted #2b2b2b' }} />

      {/* Left Pupil Line */}
      <Box sx={{ position: 'absolute', top: eyeHeight / 2, left: eyeWidth * 0.325, height: eyeHeight / 2, zIndex: 2, borderLeft: '2px dotted #2b2b2b' }} />
      {/* Right Pupil Line */}
      <Box sx={{ position: 'absolute', top: eyeHeight / 2, left: eyeWidth * 0.67, height: eyeHeight / 2, zIndex: 2, borderLeft: '2px dotted #2b2b2b' }} />



      <BiometryValue name="P" sx={{ position: 'absolute', top: '100%', left: '20%' }}>
        <Box sx={{ width: 60, textAlign: 'right', fontWeight: 600 }}>
          {biometry.white_to_white.pupil_diameter.toFixed(2)}
        </Box>
        <Typography fontWeight={100} paddingLeft={1}>[mm]</Typography>
      </BiometryValue>

      {/* Astigmatism */}

      <Box sx={{
        position: 'absolute',
        top: eyeHeight / 100,
        left: eyeWidth * 0.5,
        height: eyeHeight,
        zIndex: 2,
        borderLeft: '2px solid #c156a7',
        transform: `rotate(${(-1 * biometry.total_keratometry.maximal_axis) - 90}deg)`
      }} />

      <Box sx={{
        position: 'absolute',
        top: eyeHeight / 100,
        left: eyeWidth * 0.5,
        height: eyeHeight,
        zIndex: 2,
        borderLeft: '2px dotted #c156a7',
        transform: `rotate(${(-1 * biometry.anterior_keratometry.maximal_axis) - 90}deg)`
      }} />

      {/* Pupil Offset */}

      <Box sx={{
        position: 'absolute',
        top: (eyeHeight * 0.5 - 5) + (biometry.white_to_white.pupil_center_y * 50),
        left: (eyeWidth * 0.5) + (biometry.white_to_white.pupil_center_x * 50),
        height: 12,
        zIndex: 3,
        borderLeft: '2px solid white',
        transform: `rotate(45deg)`
      }} />
      <Box sx={{
        position: 'absolute',
        top: (eyeHeight * 0.5 - 5) + (biometry.white_to_white.pupil_center_y * 50),
        left: (eyeWidth * 0.5) + (biometry.white_to_white.pupil_center_x * 50),
        height: 12,
        zIndex: 3,
        borderLeft: '2px solid white',
        transform: `rotate(-45deg)`
      }} />

      <Typography fontSize={0.4 * eyeHeight} sx={{ position: 'absolute', top: eyeHeight * 0.2, left: 0, zIndex: 2 }}>
        {biometry.scan_info.eye === 'left' ? 'N' : 'T'}
      </Typography>

      <img ref={eyeRef} src={eye} alt="eye" />

      <Typography fontSize={0.4 * eyeHeight} sx={{ position: 'absolute', top: eyeHeight * 0.2, left: eyeWidth * 0.83 }}>
        {biometry.scan_info.eye === 'left' ? 'T' : 'N'}
      </Typography>
    </Box>
  )

  const BiometryValue = ({ name, children, sx }: { name: string, children: React.ReactNode, sx?: SxProps }) => (
    <>
      <Box sx={{ fontSize: 18, display: 'flex', ...sx }}>
        <Box sx={{ textAlign: 'right', paddingRight: 1, width: 60 }}>{name}:</Box>
        {children}
      </Box>
    </>
  )

  const KeratometryValue = ({ name, keratometry }: { name: string, keratometry: Keratometry }) => (
    <>
      <BiometryValue name={`Ø${name}`}>
        <Box sx={{ width: 60, textAlign: 'right', fontWeight: 600 }}>
          {((keratometry.flat_k + keratometry.steep_k) / 2).toFixed(2)}
        </Box>
        <Typography fontWeight={100} paddingLeft={1}>[D]</Typography>

        <Box sx={{ width: 50, textAlign: 'right', fontWeight: 600 }}>
          {(2 * keratometry.maximal_radius * keratometry.minimal_radius / (keratometry.maximal_radius + keratometry.minimal_radius)).toFixed(2)}
        </Box>
        <Typography fontWeight={100} paddingLeft={1}>[mm]</Typography>
      </BiometryValue>

      <BiometryValue name={`${name}1`}>
        <Box sx={{ width: 60, textAlign: 'right', fontWeight: 600 }}>
          {keratometry.flat_k.toFixed(2)}
        </Box>
        <Typography fontWeight={100} paddingLeft={1}>[D]</Typography>

        <Box sx={{ width: 50, textAlign: 'right', fontWeight: 600 }}>
          {keratometry.maximal_radius.toFixed(2)}
        </Box>
        <Typography fontWeight={100} paddingLeft={1}>[mm]</Typography>
        <Typography fontWeight={100} paddingLeft={1} paddingRight={0.5}>@</Typography>

        <Box sx={{ width: 60, textAlign: 'right', fontWeight: 600 }}>
          {keratometry.maximal_axis.toFixed(2)}
        </Box>
        <Typography fontWeight={100} paddingLeft={1}>[°]</Typography>
      </BiometryValue>

      <BiometryValue name={`${name}2`}>
        <Box sx={{ width: 60, textAlign: 'right', fontWeight: 600 }}>
          {keratometry.steep_k.toFixed(2)}
        </Box>
        <Typography fontWeight={100} paddingLeft={1}>[D]</Typography>

        <Box sx={{ width: 50, textAlign: 'right', fontWeight: 600 }}>
          {keratometry.minimal_radius.toFixed(2)}
        </Box>
        <Typography fontWeight={100} paddingLeft={1}>[mm]</Typography>
        <Typography fontWeight={100} paddingLeft={1} paddingRight={0.5}>@</Typography>

        <Box sx={{ width: 60, textAlign: 'right', fontWeight: 600 }}>
          {keratometry.minimal_axis.toFixed(2)}
        </Box>
        <Typography fontWeight={100} paddingLeft={1}>[°]</Typography>
      </BiometryValue>

      <BiometryValue name={`Δ${name}`}>
        <Box sx={{ width: 60, textAlign: 'right', fontWeight: 600 }}>
          {keratometry.delta_d.toFixed(2)}
        </Box>
        <Typography fontWeight={100} paddingLeft={1}>[D] @ </Typography>
        <Box sx={{ width: 60, textAlign: 'right', fontWeight: 600 }}>
          {keratometry.maximal_axis.toFixed(2)}
        </Box>
        <Typography fontWeight={100} paddingLeft={1}>[°]</Typography>
      </BiometryValue>
      <Box sx={{ height: 10 }} />
    </>
  )

  return (
    <Item variant="outlined" sx={{ paddingLeft: 2, paddingRight: 2 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={7}>

          <Typography variant="h5" sx={{ marginBottom: 1, fontSize: 20, marginTop: 2 }}>
            Segment Lengths
          </Typography>

          <BiometryValue name="AL">
            <Box sx={{ width: 60, textAlign: 'right', fontWeight: 600 }}>
              {biometry.axial_length.value.toFixed(2)}
            </Box>
            <Typography fontWeight={100} paddingLeft={1}>[mm]</Typography>
          </BiometryValue>

          <BiometryValue name="ACD">
            <Box sx={{ width: 60, textAlign: 'right', fontWeight: 600 }}>
              {biometry.anterior_chamber_depth.value.toFixed(2)}
            </Box>
            <Typography fontWeight={100} paddingLeft={1}>[mm]</Typography>
          </BiometryValue>

          <BiometryValue name="LT">
            <Box sx={{ width: 60, textAlign: 'right', fontWeight: 600 }}>
              {biometry.lens_thickness.value.toFixed(2)}
            </Box>
            <Typography fontWeight={100} paddingLeft={1}>[mm]</Typography>
          </BiometryValue>

          <BiometryValue name="CCT">
            <Box sx={{ width: 60, textAlign: 'right', fontWeight: 600 }}>
              {(biometry.central_corneal_thickness.value * 1000).toFixed(2)}
            </Box>
            <Typography fontWeight={100} paddingLeft={1}>[μm]</Typography>
          </BiometryValue>

          <Typography variant="h5" sx={{ marginBottom: 1, fontSize: 20, marginTop: 2 }}>
            Total Keratometry
          </Typography>
          <KeratometryValue name="TK" keratometry={biometry.total_keratometry} />

          {fullKeratometry &&
            (
              <>
                <Typography variant="h5" sx={{ marginBottom: 1, fontSize: 20, marginTop: 2 }}>
                  Anterior Keratometry
                </Typography>
                <KeratometryValue name="K" keratometry={biometry.anterior_keratometry} />

                <Typography variant="h5" sx={{ marginBottom: 1, fontSize: 20, marginTop: 2 }}>
                  Posterior Keratometry
                </Typography>
                <KeratometryValue name="PK" keratometry={biometry.posterior_keratometry} />
              </>
            )
          }

        </Grid>
        <Grid item xs={12} md={5}>
          <EyeVisualization sx={{ marginLeft: '2%', marginRight: '2%', maxWidth: '250px' }} />

          <Box sx={{ flexGrow: 1, marginTop: '40px', marginLeft: 2, minWidth: '250px' }}>
            <Box sx={{ width: 25, borderTop: '2px solid #c156a7', marginTop: 1.5, marginRight: 2, float: 'left' }} />
            <BiometryValue name='ΔTK'>
              <Box sx={{ width: 60, textAlign: 'right', fontWeight: 600 }}>
                {biometry.total_keratometry.delta_d.toFixed(2)}
              </Box>
              <Typography fontWeight={100} paddingLeft={1}>[D] @ </Typography>
              <Box sx={{ width: 60, textAlign: 'right', fontWeight: 600 }}>
                {biometry.total_keratometry.maximal_axis.toFixed(2)}
              </Box>
              <Typography fontWeight={100} paddingLeft={1}>[°]</Typography>
            </BiometryValue>

            <Box />

            <Box sx={{ width: 25, borderTop: '2px dotted #c156a7', marginTop: 1.5, marginRight: 2, float: 'left' }} />
            <BiometryValue name='ΔK'>
              <Box sx={{ width: 60, textAlign: 'right', fontWeight: 600 }}>
                {biometry.anterior_keratometry.delta_d.toFixed(2)}
              </Box>
              <Typography fontWeight={100} paddingLeft={1}>[D] @ </Typography>
              <Box sx={{ width: 60, textAlign: 'right', fontWeight: 600 }}>
                {biometry.anterior_keratometry.maximal_axis.toFixed(2)}
              </Box>
              <Typography fontWeight={100} paddingLeft={1}>[°]</Typography>
            </BiometryValue>
          </Box>
        </Grid>
      </Grid>

    </Item >
  )
}

BiometryReport.defaultProps = {
  fullKeratometry: false
}

export default BiometryReport;
