import AuthService from "./auth-service";
import { dateTimeReviver, HTTPValidationError } from "./helper";
import { ScanInfo } from "./scan-service";


export interface Biometry {
  scan_info: ScanInfo;
  anterior_chamber_depth: BiometryMeasurmentValue;
  aqueous_depth: BiometryMeasurmentValue;
  axial_length: BiometryMeasurmentValue;
  central_corneal_thickness: BiometryMeasurmentValue;
  lens_thickness: BiometryMeasurmentValue;
  total_keratometry: Keratometry;
  anterior_keratometry: Keratometry;
  posterior_keratometry: Keratometry;
  white_to_white: WhiteToWhite;
}

export interface BiometryMeasurmentValue {
  value: number;
  quality: string;
}

export interface Keratometry {
  maximal_radius: number;
  flat_k: number;
  maximal_axis: number;
  minimal_radius: number;
  steep_k: number;
  minimal_axis: number;
  refractive_index: number;
  delta_d: number;
  delta_axis: number;
  quality: string;
  refractive_index_aqueous_humor?: null;
}

export interface WhiteToWhite {
  center_x: number;
  center_y: number;
  diameter: number;
  pupil_center_x: number;
  pupil_center_y: number;
  pupil_diameter: number;
  quality: string;
}


/**
 * Gets the biometry data of a given scan id
 * @param scanId Scan Id of the biometry scan
 * @returns A tuple of biometry (left, right)
 */
const getBiometryById = async (scanId: string) => {
  const response = await fetch(`/api/biometry/${scanId}`, {
    headers: AuthService.authHeader()
  });

  if (!response.ok) {
    throw Object.assign(new HTTPValidationError(), JSON.parse(await response.text(), dateTimeReviver));
  }

  return JSON.parse(await response.text(), dateTimeReviver) as [Biometry, Biometry];
};

const BiometryService = {
  getBiometryById,
};

export default BiometryService;
