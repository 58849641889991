import { ChangeEvent, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Pagination, PaginationItem, Tooltip, Typography, } from "@mui/material";

import Item from "../components/Item";
import useAPIError from "../common/usaAPIError";
import APIErrorNotification from "../components/APIErrorNotification";
import { InfoOutlined } from "@mui/icons-material";
import PatientService, { PatientResponse } from "../services/patient-service";
import { LAYOUT_MAX_WIDTH } from "../common/const";



function Dashboard() {
  const { addError } = useAPIError();
  const [searchParams] = useSearchParams();
  const [pages, setPages] = useState(0);
  const [patients, setPatients] = useState<PatientResponse[]>([]);

  const handleChange = (event: ChangeEvent<unknown>, value: number) => {
    searchParams.set("page", value.toString())
  };

  let q = searchParams.get('q') || "";
  let p = parseInt(searchParams.get('page') || "1");

  useEffect(() => {
    (async () => {
      try {
        const response = await PatientService.getPatients(q, p);
        setPatients(response.response);
        setPages(Math.ceil(response.count / response.per_page))
      } catch (err: any) {
        if (err.detail) {
          addError(err.detail, "error");
        } else {
          addError("Could not load scans", "error");
        }
      }
    })();
  }, [addError, setPatients, setPages, q, p]);

  return (
    <>
      {q ?
        <Typography variant="h5" sx={{ marginLeft: 1, marginTop: 2, marginBottom: 1 }}>
          Search results for '{q}'
        </Typography> :
        <Typography variant="h5" sx={{ marginLeft: 1, marginTop: 2, marginBottom: 1 }}>
          Patients with recent Scans

          <Tooltip
            placement="right"
            title="A list of all patients. The patients with the latest scans are listed first."
          >
            <InfoOutlined fontSize="small" sx={{ ml: 'auto', color: 'grey.500', marginLeft: 2 }} />
          </Tooltip>
        </Typography>
      }

      <Item variant="outlined" sx={{ maxWidth: LAYOUT_MAX_WIDTH }}>
        <List>
          {(patients.length && patients.map((p) => {

            const leftScans = p.scans.filter((s) => s.eye.toLowerCase() === "left")
            const rightScans = p.scans.filter((s) => s.eye.toLowerCase() === "right");

            return (
              <ListItem
                key={p.patient.id}
                disablePadding
                secondaryAction={
                  <>
                    <Tooltip
                      placement="left"
                      title={leftScans.length ? 'Latest scan (left eye)' : 'No scans of the left eye found'}
                    >
                      <span>
                        <IconButton sx={{ width: '50px' }} {...(leftScans.length ? { to: `/scan/${leftScans[0].id}`, component: Link } : { disabled: true })} aria-label="View latest Scan">
                          L
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip
                      placement="right"
                      title={leftScans.length ? 'Latest scan (right eye)' : 'No scans of the right eye found'}
                    >
                      <span>
                        <IconButton sx={{ width: '50px' }} {...(rightScans.length ? { to: `/scan/${rightScans[0].id}`, component: Link } : { disabled: true })} aria-label="View latest Scan">
                          R
                        </IconButton>
                      </span>
                    </Tooltip>
                  </>
                }
              >
                <ListItemButton dense component={Link} to={`/patient/${p.patient.id}`}>
                  <ListItemAvatar>
                    <Avatar sx={{ textTransform: 'uppercase' }}>{p.scans.length}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    sx={{
                      '& p': {
                        minHeight: '20px'
                      }
                    }}
                    primary={
                      <>
                        <Typography component={'span'} variant="body1" sx={{ fontWeight: 'bold' }}>{`${p.patient.firstname} ${p.patient.lastname}`}</Typography>
                        <Typography component={'span'} variant="body1" sx={{ marginLeft: 1 }}>(1996-03-12)</Typography>
                      </>
                    }
                    secondary={p.patient.patient_id}
                  />
                </ListItemButton>
              </ListItem>
            )
          })) || <ListItem>No scans found ...</ListItem>}

        </List>
      </Item>

      {pages > 1 ? <Pagination
        sx={{ marginTop: 2 }}
        count={pages}
        variant="outlined"
        shape="rounded"
        page={p}
        onChange={handleChange}
        renderItem={(item) => (
          <PaginationItem
            component={Link}
            to={'/?' + new URLSearchParams({
              ...(q && { q }),
              ...(((item.page || 1) > 1) && { page: (item.page || 1).toString() })
            })}
            {...item}
          />
        )}
      /> : <></>}

      <APIErrorNotification />
    </>
  );
}

export default Dashboard;
