import logo from '../logo-full.svg';

import Logout from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  Button,
  ClickAwayListener,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Typography,
  styled,
} from '@mui/material';

import { createContext, useContext, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { KeyboardArrowDown, People, UploadFile } from '@mui/icons-material';
import { blue, grey } from '@mui/material/colors';
import AuthService from '../services/auth-service';
import { Patient } from '../services/helper';
import IOLIcon from './IOLIcon';
import Search from './Search';

const HeaderImage = styled('img')(() => ({
  height: '66px',
  marginLeft: '16px',
  marginBottom: '20px',
  marginTop: '28px',
}));

type LayoutProps = {
  children?: React.ReactNode;
}

export type PatientContextType = [
  patient: Patient | undefined,
  setPatient: React.Dispatch<React.SetStateAction<Patient | undefined>>
]
export const PatientContext = createContext<PatientContextType | undefined>(undefined);
export const usePatientContext = () => useContext(PatientContext) as PatientContextType;

function Layout({ children }: LayoutProps) {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false)

  const [patient, setPatient] = useState<Patient | undefined>(undefined);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);


  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    AuthService.logout();
    navigate('/login');
  };

  const handleDrawerToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClickAway = () => {
    setMenuOpen(false);
  };

  const open = Boolean(anchorEl);

  const SelectedMenuStyle = {
    color: blue[800],
    borderLeft: '4px solid'
  }

  const DeselectedMenuStyle = {
    color: grey[900],
    borderLeft: '4px solid transparent'
  }

  const Menu = () => (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{
        position: 'fixed',
        width: '292px',
        height: '100vh',
        backgroundColor: 'white',
        zIndex: 2,
        borderRight: '1px solid rgb(229, 231, 235)'
      }}>
        <Box>
          <a href="/">
            <HeaderImage src={logo} alt="logo" />
          </a>
        </Box>
        <MenuList sx={{
          marginTop: '24px',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}>
          <MenuItem component="a" href="/" sx={{ ...(location.pathname === '/' ? SelectedMenuStyle : DeselectedMenuStyle) }}>
            <ListItemIcon>
              <People sx={{ color: '#d3d3d3' }} fontSize='small' />
            </ListItemIcon>
            <ListItemText>
              <Typography fontSize='1em' fontWeight={500}>Dashboard</Typography>
            </ListItemText>
          </MenuItem>
          <MenuItem component="a" href="/upload" sx={{ ...(location.pathname === '/upload' ? SelectedMenuStyle : DeselectedMenuStyle) }}>
            <ListItemIcon>
              <UploadFile sx={{ color: '#d3d3d3' }} fontSize='small' />
            </ListItemIcon>
            <ListItemText>
              <Typography fontSize='1em' fontWeight={500}>Upload Cornea Scans</Typography>
            </ListItemText>
          </MenuItem>
          {patient &&
            (
              <>
                <Divider sx={{ marginTop: '24px !important' }} />
                <MenuItem
                  component="a"
                  href={`/patient/${params.patientId}`}
                  sx={{ ...(/^\/patient\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(location.pathname) ? SelectedMenuStyle : DeselectedMenuStyle) }}>
                  <ListItemIcon>
                    <People sx={{ color: '#d3d3d3' }} fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography fontSize='1em' fontWeight={600}>{`${patient.firstname} ${patient.lastname}`}</Typography>
                  </ListItemText>
                </MenuItem>
                <MenuItem
                  component="a"
                  href={`/patient/${params.patientId}/iol`}
                  sx={{ ...(/^\/patient\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/iol$/.test(location.pathname) ? SelectedMenuStyle : DeselectedMenuStyle) }}>
                  <ListItemIcon>
                    <IOLIcon />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography fontSize='1em'>IoL Planner</Typography>
                  </ListItemText>
                </MenuItem>
              </>
            )
          }
        </MenuList>
        <Button
          fullWidth
          onClick={handleClick}
          sx={{
            height: "40px",
            marginTop: '16px',
            position: 'absolute',
            bottom: '16px',
          }}
          endIcon={<KeyboardArrowDown />}
        >
          {AuthService.getCurrentUser().email}
        </Button>
        <Popover
          open={open}
          onClose={handleClose}
          sx={{
            top: 'calc(100vh - 140px)',
          }}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 0, left: 0 }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <List sx={{ width: 325 }}>
            <ListItem disablePadding>
              <ListItemButton onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </ListItem>
          </List>
        </Popover>
      </Box>

    </ClickAwayListener>
  )

  const Toolbar = () => (
    <Grid container spacing={1} sx={{ borderBottom: '1px solid rgb(229, 231, 235)' }}>
      <Grid item xs sx={{ display: 'flex' }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ marginLeft: 2, display: { xs: 'inherit', md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>

        <Search sx={{
          bgcolor: "white",
          marginTop: "16px",
          marginBottom: "16px",
          marginLeft: '16px',
          marginRight: 0,
        }} />
      </Grid>
      <Grid item sx={{ paddingRight: '16px', width: '350px' }}>

      </Grid>
    </Grid>
  )


  return (
    <Container maxWidth={false} sx={{ padding: '0px !important' }}>
      <Box style={{ width: '300px' }} sx={{
        display: { xs: menuOpen ? 'block' : 'none', md: 'block' }
      }}>
        <Menu />
      </Box>
      <Box sx={{ marginLeft: { xs: 'none', md: '300px' } }}>
        <Toolbar />
        <Box sx={{ padding: '16px' }}>
          <PatientContext.Provider value={[patient, setPatient]}>
            {children}
          </PatientContext.Provider>
        </Box>
      </Box>
    </Container >
  );
}

export default Layout;
