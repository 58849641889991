import SearchIcon from '@mui/icons-material/Search';
import { Box, FormControl, InputLabel, OutlinedInput, SxProps, Theme } from "@mui/material";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";


type SearchProps = {
  sx?: SxProps<Theme>
  placeholder?: string
}

function Search(props: SearchProps) {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get('q') || "");


  const doSearch = () => {
    if (search === "") {
      // clean url
      navigate('/');
    } else {
      navigate({ pathname: "/", search: `?q=${search}` });
    }
  };

  const handleChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <Box sx={{ display: 'flex', ...(props.sx && props.sx) }}>
      {/*
      disable chrome from randomly autofilling the search in put ...
      https://stackoverflow.com/a/54712517/12356463
      */}
      <input type="text" id="username" style={{ width: 0, height: 0, visibility: 'hidden', position: 'absolute', left: 0, top: 0 }} />
      <SearchIcon sx={{ marginTop: '8px', color: '#6d6d6d' }} />
      <FormControl
        sx={{ flexGrow: 1 }}
        variant="outlined"
        size="small"
      >
        <InputLabel sx={{ backgroundColor: 'white' }} htmlFor="search">{props.placeholder}</InputLabel>
        <OutlinedInput
          id="search"
          type="search"
          value={search}
          sx={{
            '& fieldset': {
              borderColor: 'white',
            },
            '&:hover': {
              '& fieldset': {
                borderColor: 'white !important',
              },
            },
          }}
          onChange={handleChange()}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              doSearch();
              ev.preventDefault();
            }
          }}
        />

      </FormControl>
    </Box>
  )
};


Search.defaultProps = {
  placeholder: "Search ..."
}

export default Search;
