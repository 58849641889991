import AuthService from "./auth-service";
import { dateTimeReviver, HTTPValidationError, Patient } from "./helper";


export type KeratoconusABCDScoreRating = 0 | 1 | 2 | 3 | 4;

export type ScanMetadata = {
  scan_info: ScanInfo;
  patient: Patient;
};

export type ScanInfo = {
  id: string;
  patient_id: string;
  firstname: string;
  lastname: string;
  timestamp: Date;
  eye: string;
  comment: string;
  filename: string | null;
};


type KeratoconusABCDValue = {
  value: number;
  stage: KeratoconusABCDScoreRating
}

export type ABCDScore = {
  axial_anterior: KeratoconusABCDValue;
  axial_posterior: KeratoconusABCDValue;
  pachymetry: KeratoconusABCDValue;
};


type AmountOffset = {
  diopters: number;
  offset_degrees: number;
}

export type AsymmetryScore = {
  anterior_asymmetry: AmountOffset;
  anterior_astigmatism: AmountOffset;
  posterior_asymmetry: AmountOffset;
  posterior_astigmatism: AmountOffset;
};

export type ScanDiagnosis = {
  scan_info: ScanInfo;
  patient: Patient;
  abcd_score: ABCDScore;
  asymmetry_score: AsymmetryScore;
};

export type PredictionReport = {
  labels: string[];
  predictions: number[];
  prediction_index: number;
};

export type ProgressionStage = 0 | 1 | 2;

export type ProgressionAmount = {
  amount: number;
  stage: ProgressionStage;
}

type Progression = {
  timespan: Date;
  axial_anterior: ProgressionAmount;
  axial_posterior: ProgressionAmount;
  elevation_anterior: ProgressionAmount;
  elevation_posterior: ProgressionAmount;
  anterior_asymmetry: ProgressionAmount;
  posterior_asymmetry: ProgressionAmount;
  anterior_astigmatism: ProgressionAmount;
  posterior_astigmatism: ProgressionAmount;
  pachymetry: ProgressionAmount;
}

export type ProgressionReport = {
  scan_1: PredictionReport;
  scan_2: PredictionReport;
  progression: PredictionReport;
  treatment_progression: Progression;
  asymmetry_progression: AsymmetryScore;
}

export enum Device {
  pentacam = "Pentacam",
  eyestar900 = "Eyestar 900",
  casia2 = "CASIA2",
  ms39 = "MS39",
  iolmaster700 = "IOLMaster 700",
}

const getScans = async (q: string = "", page: number = 1, perPage: number = 10): Promise<ScanMetadata[]> => {
  const response = await fetch('/api/scans?' + new URLSearchParams({
    q,
    page: page.toString(),
    per_page: perPage.toString()
  }), {
    headers: AuthService.authHeader()
  });

  if (!response.ok) {
    throw Object.assign(new HTTPValidationError(), JSON.parse(await response.text(), dateTimeReviver));
  }

  return JSON.parse(await response.text(), dateTimeReviver) as ScanMetadata[];
};

const getScan = async (scanId: string) => {
  const response = await fetch(`/api/scans/${scanId}`, {
    headers: AuthService.authHeader()
  });

  if (!response.ok) {
    throw Object.assign(new HTTPValidationError(), JSON.parse(await response.text(), dateTimeReviver));
  }

  return JSON.parse(await response.text(), dateTimeReviver) as ScanDiagnosis;
};

const getScansByPatientId = async (patientId: string) => {
  const response = await fetch(`/api/patients/${patientId}/scans`, {
    headers: AuthService.authHeader()
  });

  if (!response.ok) {
    throw Object.assign(new HTTPValidationError(), JSON.parse(await response.text(), dateTimeReviver));
  }

  return JSON.parse(await response.text(), dateTimeReviver) as ScanMetadata[];
};

const getBiometryByPatientId = async (patientId: string) => {
  const response = await fetch(`/api/patients/${patientId}/biometry`, {
    headers: AuthService.authHeader()
  });

  if (!response.ok) {
    throw Object.assign(new HTTPValidationError(), JSON.parse(await response.text(), dateTimeReviver));
  }

  return JSON.parse(await response.text(), dateTimeReviver) as ScanMetadata[];
};

const downloadKeratoconusReport = async (scanId: string) => {
  const response = await fetch(`/api/scans/${scanId}/cornea-report`, {
    headers: AuthService.authHeader()
  });

  if (!response.ok) {
    throw Object.assign(new HTTPValidationError(), JSON.parse(await response.text(), dateTimeReviver));
  }

  //Create a Blob from the PDF Stream
  const file = new Blob([await response.blob()], { type: "application/pdf" });
  //Build a URL from the file
  const fileURL = URL.createObjectURL(file);
  //Open the URL on new Window
  const pdfWindow = window.open();
  if (pdfWindow) {
    pdfWindow.location.href = fileURL;
  }
};

const getReport = async (scanId: string) => {
  const response = await fetch(`/api/scans/${scanId}/prediction`, {
    headers: AuthService.authHeader()
  });

  if (!response.ok) {
    throw Object.assign(new HTTPValidationError(), JSON.parse(await response.text(), dateTimeReviver));
  }

  return JSON.parse(await response.text(), dateTimeReviver) as PredictionReport;
};

const uploadScans = async (scans: File[], device: Device) => {
  const data = new FormData();
  for (const scan of scans) {
    data.append('scans', scan);
  }

  data.append('device', device.toString())

  const response = await fetch(`/api/scans`, {
    method: 'POST',
    headers: AuthService.authHeader(),
    body: data
  });

  if (!response.ok) {
    throw Object.assign(new HTTPValidationError(), JSON.parse(await response.text(), dateTimeReviver));
  }

  return JSON.parse(await response.text(), dateTimeReviver) as ScanMetadata;
};

const getProgression = async (scanId1: string, scanId2: string) => {
  const response = await fetch(`/api/scans/progression/${scanId1}..${scanId2}`, {
    headers: AuthService.authHeader()
  });

  if (!response.ok) {
    throw Object.assign(new HTTPValidationError(), JSON.parse(await response.text(), dateTimeReviver));
  }

  return JSON.parse(await response.text(), dateTimeReviver) as ProgressionReport;
};

const ScanService = {
  getScans,
  getScansByPatientId,
  getBiometryByPatientId,
  getScan,
  getReport,
  uploadScans,
  downloadKeratoconusReport,
  getProgression
};

export default ScanService;
