import { Backdrop, Skeleton, styled } from "@mui/material";
import { useEffect, useState } from "react";
import AuthService from "../services/auth-service";


const ClickableImage = styled('img')(() => ({
  cursor: 'pointer'
}));

const BigImage = styled('img')(() => ({
  background: 'white',
  borderRadius: 4
}));

type AuthImageProps = {
  src: string
  width?: string
  height?: string
  skeletonHeight?: string
  load?: boolean
}

function AuthImage(props: AuthImageProps) {
  const [imgSrc, setImgSrc] = useState('');
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    (async () => {
      if (props.load) {
        const response = await fetch(props.src, {
          method: 'GET',
          headers: AuthService.authHeader()
        });

        setImgSrc(URL.createObjectURL(await response.blob()));
      }
    })();
  }, [props.src, props.load]);

  return (
    <>
      {
        (imgSrc !== '' && props.load) ? (
          <>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
              onClick={handleClose}
            >
              <BigImage src={imgSrc} alt="" height="80%" />
            </Backdrop>
            <ClickableImage onClick={handleToggle} src={imgSrc} alt=""
              {...(props.width && { width: props.width })}
              {...(props.height && { height: props.height })} />
          </>
        ) : (
          <Skeleton variant="rectangular"
            {...(props.width && { width: props.width })}
            {...(props.skeletonHeight && { height: props.skeletonHeight })}
          />
        )
      }
    </>
  )
}

AuthImage.defaultProps = {
  load: true
};

export default AuthImage;