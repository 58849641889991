import { AlertColor } from '@mui/material';
import React, { useState, useCallback } from 'react';

type Error = {
  message: string;
  status: AlertColor;
};

interface APIErrorContextInterface {
  error: Error | null;
  addError: (message: string, status: AlertColor) => void;
  removeError: () => void;
}

interface APIErrorProviderProps {
  children?: React.ReactNode;
}

export const APIErrorContext = React.createContext<APIErrorContextInterface>({
  error: null,
  addError: (message: string, status: AlertColor) => { },
  removeError: () => { }
});



export default function APIErrorProvider({ children }: APIErrorProviderProps) {
  const [error, setError] = useState<Error | null>(null);

  const removeError = () => setError(null);

  const addError = (message: string, status: AlertColor) => setError({ message, status });

  const contextValue = {
    error,
    addError: useCallback((message: string, status: AlertColor) => addError(message, status), []),
    removeError: useCallback(() => removeError(), [])
  };

  return (
    <APIErrorContext.Provider value={contextValue}>
      {children}
    </APIErrorContext.Provider>
  );
}
