import { SvgIcon } from "@mui/material"

const IOLIcon = () => (
  <SvgIcon >
    <svg width="34.936mm" height="68.814mm" version="1.1" viewBox="0 0 34.936 68.814" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(-83.929 -100.48)" fill="none" stroke="#bbb" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10.3">
        <path
          d="m117.36 134.49a15.968 15.968 0 0 1-15.968 15.968 15.968 15.968 0 0 1-15.968-15.968 15.968 15.968 0 0 1 15.968-15.968 15.968 15.968 0 0 1 15.968 15.968z"
          stroke-dasharray="0.3, 0.3"
          stroke-width="4"
        />
        <path
          d="m115.33 126.61c0.0149-2.8983 0.0298-5.7965 0.0446-8.6948 8e-3 -1.6104-0.28218-4.1905-0.80684-5.7125-1.4511-4.2093-3.9336-8.247-8.3149-9.7546-5.7663-1.9114-12.836-0.43984-16.914 4.1915"
          stroke-dasharray="0.23, 0.23" stroke-width="3"
        />
        <path
          transform="rotate(180 101.68 134.89)"
          d="m115.33 126.61c0.0149-2.8983 0.0298-5.7965 0.0446-8.6948 8e-3 -1.6104-0.28218-4.1905-0.80684-5.7125-1.4511-4.2093-3.9336-8.247-8.3149-9.7546-5.7663-1.9114-12.836-0.43984-16.914 4.1915"
          stroke-dasharray="0.23, 0.23"
          stroke-width="3"
        />
      </g>
    </svg>
  </SvgIcon>
)

export default IOLIcon
