import { Box, Typography } from "@mui/material";

function NotFound() {
  return (
    <Box sx={{
      color: '#000',
      background: '#fff',
      height: '100vh',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Typography variant="h1" sx={{
        display: 'block',
        flex: 'left',
        borderRight: '1px solid rgba(0, 0, 0,.3)',
        margin: 0,
        marginRight: '20px',
        padding: '10px 23px 10px 0',
        fontSize: '24px',
        fontWeight: 500,
        verticalAlign: 'top'
      }}>404</Typography>
      <Typography variant="h2" sx={{
        float: 'right',
        display: 'block',
        fontSize: '14px',
        fontWeight: 'normal',
        lineHeight: 'inherit',
        margin: 0,
        padding: 0
      }}>This page could not be found.</Typography>
    </Box>
  );
}

export default NotFound;