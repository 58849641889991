import * as React from 'react';
import { ThemeProvider, createTheme, useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import HtmlTooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { AsymmetryScore as AsymmetryScoreType } from '../services/scan-service';


const grey = {
  50: '#F3F6F9',
  100: '#EAEEF3',
  200: '#E5E8EC',
  300: '#D7DCE1',
  400: '#BFC7CF',
  500: '#AAB4BE',
  600: '#96A3B0',
  700: '#8796A5',
  800: '#5A6978',
  900: '#3D4752',
};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <HtmlTooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});



type AsymmetryScoreProps = {
  progression?: boolean;
  score: AsymmetryScoreType;
};

function AsymmetryScore(props: AsymmetryScoreProps) {
  /*
   * Note: this demo use `theme.palette.mode` from `useTheme` to make dark mode works in the documentation only.
   *
   * Normally, you would implement dark mode via internal state and/or system preference at the root of the application.
   * For more detail about toggling dark mode: https://mui.com/customization/palette/#toggling-color-mode
   */
  const globalTheme = useTheme();
  const mode = globalTheme.palette.mode;
  const theme = React.useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
          fontWeightRegular: 500,
        },
        components: {
          // @ts-ignore
          MuiTimelineItem: {
            styleOverrides: {
              root: {
                minHeight: 0,
              },
            },
          },
          MuiTimelineDot: {
            styleOverrides: {
              root: {
                padding: 3,
              },
            },
          },
          MuiTimelineConnector: {
            styleOverrides: {
              root: {
                margin: '-10px 0',
              },
            },
          },
          MuiTimelineContent: {
            styleOverrides: {
              root: {
                fontSize: '0.775rem',
                color: mode === 'dark' ? grey[100] : grey[800],
              },
            },
          },
        },
      }),
    [mode],
  );

  const sign = (value: number): string => {
    if (props.progression && value > 0) {
      return '+';
    } else {
      return '';
    }
  }


  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ ml: 2, flex: 1 }}>
        <Box sx={{ display: 'flex', marginTop: '4px' }}>
          <Typography
            color={mode === 'dark' ? 'grey.400' : 'text.secondary'}
            variant="body1"
            sx={{ mb: 1 }}
          >
            Asymmetry and Astigmatism
          </Typography>
          <CustomWidthTooltip
            placement="left"
            title={
              <React.Fragment>
                <Typography color="inherit">Asymmetry and Astigmatism</Typography>
                The asymmetry/astigmatism score displays the amount of asymmetry/astigmatism
                in dipoters and the offset in degrees. It is also visualized in the axial
                refractive plots.
              </React.Fragment>
            }
          >
            <InfoOutlined fontSize="small" sx={{ ml: 'auto', color: 'grey.500' }} />
          </CustomWidthTooltip>
        </Box>
        <Timeline
          sx={{
            pl: 0,
            py: 0,
            my: 0,
            '& .MuiTimelineItem-root:before': { display: 'none' },
          }}
        >
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot variant="outlined" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Box>Anterior Asymmetry: <b>{sign(props.score.anterior_asymmetry.diopters)}{props.score.anterior_asymmetry.diopters.toFixed(2)}</b> [D] @ <b>{sign(props.score.anterior_asymmetry.offset_degrees)}{props.score.anterior_asymmetry.offset_degrees.toFixed(4)}</b> [°]</Box>
              <Box>Anterior Astigmatism: <b>{sign(props.score.anterior_astigmatism.diopters)}{props.score.anterior_astigmatism.diopters.toFixed(2)}</b> [D] @ <b>{sign(props.score.anterior_astigmatism.offset_degrees)}{props.score.anterior_astigmatism.offset_degrees.toFixed(4)}</b> [°]</Box>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot variant="outlined" />
            </TimelineSeparator>
            <TimelineContent>
              <Box>Posterior Asymmetry: <b>{sign(props.score.posterior_asymmetry.diopters)}{props.score.posterior_asymmetry.diopters.toFixed(2)}</b> [D] @ <b>{sign(props.score.posterior_asymmetry.offset_degrees)}{props.score.posterior_asymmetry.offset_degrees.toFixed(4)}</b> [°]</Box>
              <Box>Posterior Astigmatism: <b>{sign(props.score.posterior_astigmatism.diopters)}{props.score.posterior_astigmatism.diopters.toFixed(2)}</b> [D] @ <b>{sign(props.score.posterior_astigmatism.offset_degrees)}{props.score.posterior_astigmatism.offset_degrees.toFixed(4)}</b> [°]</Box>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </Box>
    </ThemeProvider>
  );
}

AsymmetryScore.defaultProps = {
  progression: false
}

export default AsymmetryScore;
