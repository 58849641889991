import { Box, Grid, Typography } from "@mui/material";
import { PredictionReport } from "../services/scan-service";

import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type ScanReportProps = {
  report: PredictionReport;
  progression: boolean;
}

function ScanReport(props: ScanReportProps) {
  const calculateMarginTop = (prediction: number, progression: boolean): number => {
    const marginTop = ((100 - prediction * 100) / (progression ? 2 : 1)) - (prediction < 0 ? Math.abs(prediction * 100) / 2 : 0);
    if (marginTop > 99) {
      // preserves layout breaks
      return 99.0;
    } else {
      return marginTop;
    }
  }

  /**
   * Returns the description and formating as `React.ReactNode`
   * depending on whether a progression is reported or a normal
   * prediction report.
   * @param prediction The actual prediciton to print
   * @param progression Whether a progression is printed or not
   * @param predictions All predictions
   */
  const getPredictionDescription = (prediction: number, progression: boolean, predictions: number[]): React.ReactNode => {
    const iconStyle = {
      marginLeft: '16px',
    }

    if (!progression) {
      return <>{(prediction * 100).toFixed(2)}%</>
    }

    if (prediction > 0) {
      if (Math.abs((Math.max(...predictions) - prediction)) < Number.EPSILON) {
        return <KeyboardDoubleArrowUpIcon sx={{...iconStyle}} />
      } else {
        return <KeyboardArrowUpIcon sx={{...iconStyle}} />
      }
    } else {
      if (Math.abs((Math.min(...predictions) - prediction)) < Number.EPSILON) {
        return <KeyboardDoubleArrowDownIcon sx={{...iconStyle}} />
      } else {
        return <KeyboardArrowDownIcon sx={{...iconStyle}} />
      }
    }
  }


  return (
    <Grid container spacing={1}>
      { props.report.predictions.map((prediction: number, index: number) =>
          <Grid key={index} item xs={1.7}>
            <Box sx={{
              height: '102px',
              border: '1px solid rgb(3, 169, 244)',
              borderRadius: 0.5,
              marginBottom: 1
            }}>
              <Typography sx={{
                float: 'left',
                position: 'relative',
                left: 'calc(30% - 14px)',
                top: !props.progression ? 'calc(50% - 10px)' : (prediction > 0) ? 'calc(30% - 10px)' : 'calc(70% - 10px)'
              }}>{getPredictionDescription(prediction, props.progression, props.report.predictions)}</Typography>
              {props.progression && <Box sx={{position: 'relative', height: 0, width: '100%', borderBottom: '1px solid rgb(3, 169, 244)', top: '50px'}} />}

              <Box sx={{
                height: `${(Math.abs(prediction) * 100) / (props.progression ? 2 : 1)}px`,
                bgcolor: "rgb(229, 246, 253)",
                marginTop: `${calculateMarginTop(prediction, props.progression)}px`
              }}></Box>
            </Box>

            <Typography sx={{ fontSize: '0.8rem' }}>{props.report.labels[index]}</Typography>
          </Grid>
    )}
    </Grid>
  );
}

ScanReport.defaultProps = {
  progression: false
}

export default ScanReport;
