import * as React from 'react';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import HtmlTooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { ABCDScore } from '../services/scan-service';


const grey = {
  50: '#F3F6F9',
  100: '#EAEEF3',
  200: '#E5E8EC',
  300: '#D7DCE1',
  400: '#BFC7CF',
  500: '#AAB4BE',
  600: '#96A3B0',
  700: '#8796A5',
  800: '#5A6978',
  900: '#3D4752',
};



type KeratoconusABCDScoreProps = {
  score: ABCDScore;
};

const RatingColorLookup = {
  0: { foreground: '#4caf50', background: '#edf7ed' },
  1: { foreground: 'rgb(102, 60, 0)', background: 'rgb(255, 244, 229)' },
  2: { foreground: 'rgb(102, 60, 0)', background: 'rgb(255, 244, 229)' },
  3: { foreground: '#5f2120', background: '#fdeded' },
  4: { foreground: '#5f2120', background: '#fdeded' }
};


export default function KeratoconusABCDScore(props: KeratoconusABCDScoreProps) {
  /*
   * Note: this demo use `theme.palette.mode` from `useTheme` to make dark mode works in the documentation only.
   *
   * Normally, you would implement dark mode via internal state and/or system preference at the root of the application.
   * For more detail about toggling dark mode: https://mui.com/customization/palette/#toggling-color-mode
   */
  const globalTheme = useTheme();
  const mode = globalTheme.palette.mode;
  const theme = React.useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
          fontWeightRegular: 500,
        },
        components: {
          // @ts-ignore
          MuiTimelineItem: {
            styleOverrides: {
              root: {
                minHeight: 0,
              },
            },
          },
          MuiTimelineDot: {
            styleOverrides: {
              root: {
                padding: 3,
              },
            },
          },
          MuiTimelineConnector: {
            styleOverrides: {
              root: {
                margin: '-10px 0',
              },
            },
          },
          MuiTimelineContent: {
            styleOverrides: {
              root: {
                fontSize: '0.775rem',
                color: mode === 'dark' ? grey[100] : grey[800],
              },
            },
          },
        },
      }),
    [mode],
  );
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ ml: 2, flex: 1 }}>
        <Box sx={{ display: 'flex', marginTop: '4px' }}>
          <Typography
            color={mode === 'dark' ? 'grey.400' : 'text.secondary'}
            variant="body1"
            sx={{ mb: 1 }}
          >
            Keratoconus ABCD Score
          </Typography>
          <HtmlTooltip
            placement="left"
            title={
              <React.Fragment>
                <Typography color="inherit">Keratoconus ABCD score</Typography>


                Belin, M. W., & Duncan, J. K. (2016).
                Keratoconus: The ABCD Grading System. Keratokonus:
                Das ABCD-System zur Stadieneinteilung.
                Klinische Monatsblatter fur Augenheilkunde, 233(6), 701–707. <a href="https://doi.org/10.1055/s-0042-100626">https://doi.org/10.1055/s-0042-100626</a>
              </React.Fragment>
            }
          >
            <InfoOutlined fontSize="small" sx={{ ml: 'auto', color: 'grey.500' }} />
          </HtmlTooltip>
        </Box>
        <Timeline
          sx={{
            pl: 0,
            py: 0,
            my: 0,
            '& .MuiTimelineItem-root:before': { display: 'none' },
          }}
        >
          <TimelineItem>
            <TimelineSeparator>
            <Box
                sx={{
                  p: 0.2,
                  borderRadius: 0.5,
                  minWidth: 25,
                  textAlign: 'center',
                  bgcolor: RatingColorLookup[props.score.axial_anterior.stage].background,
                  color: RatingColorLookup[props.score.axial_anterior.stage].foreground,
                  border: `1px solid ${RatingColorLookup[props.score.axial_anterior.stage].foreground}`,
                  typography: 'body2',
                }}
              >
                A{props.score.axial_anterior.stage}
              </Box>
            </TimelineSeparator>
            <TimelineContent>Axial Anterior (Ø 3mm) [D]: <b>{props.score.axial_anterior.value.toFixed(2)}</b></TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <Box
                sx={{
                  p: 0.2,
                  borderRadius: 0.5,
                  minWidth: 25,
                  textAlign: 'center',
                  bgcolor: RatingColorLookup[props.score.axial_posterior.stage].background,
                  color: RatingColorLookup[props.score.axial_posterior.stage].foreground,
                  border: `1px solid ${RatingColorLookup[props.score.axial_posterior.stage].foreground}`,
                  typography: 'body2',
                }}
              >
                B{props.score.axial_posterior.stage}
              </Box>
            </TimelineSeparator>
            <TimelineContent>Axial Posterior(Ø 3mm) [D]: <b>{props.score.axial_posterior.value.toFixed(2)}</b></TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <Box
                sx={{
                  p: 0.2,
                  borderRadius: 0.5,
                  minWidth: 25,
                  textAlign: 'center',
                  bgcolor: RatingColorLookup[props.score.pachymetry.stage].background,
                  color: RatingColorLookup[props.score.pachymetry.stage].foreground,
                  border: `1px solid ${RatingColorLookup[props.score.pachymetry.stage].foreground}`,
                  typography: 'body2',
                }}
              >
                C{props.score.pachymetry.stage}
              </Box>
            </TimelineSeparator>
            <TimelineContent>Min Pachymetry [μm]: <b>{props.score.pachymetry.value.toFixed(2)}</b></TimelineContent>
          </TimelineItem>
        </Timeline>
      </Box>
    </ThemeProvider>
  );
}