
import { Grid, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LAYOUT_MAX_WIDTH } from "../common/const";
import useAPIError from "../common/usaAPIError";
import BiometryReport from "../components/Biometry";
import { usePatientContext } from "../components/Layout";
import PatientInfo from "../components/PatientInfo";
import BiometryService, { Biometry } from "../services/biometry-service";
import { eyeSideName } from "../services/helper";
import PatientService from "../services/patient-service";
import ScanService from "../services/scan-service";

const Title = styled('h1')({
  marginTop: 0,
});

function IOLPlanner() {
  const params = useParams();
  const { addError } = useAPIError();
  const [patient, setPatient] = usePatientContext();
  const [patientBiometry, setPatientBiometry] = useState<[Biometry?, Biometry?]>();

  const [loading, setLoading] = useState(true);


  useEffect(() => {
    (async () => {
      setLoading(true);
      try {

        // get all scans of this patient
        if (params.patientId) {
          const patientResponse = await PatientService.getPatientById(params.patientId);
          setPatient(patientResponse);


          const patientBiometryResponse = await ScanService.getBiometryByPatientId(params.patientId);
          if (patientBiometryResponse.length > 0) {
            const patientBiometry = await BiometryService.getBiometryById(patientBiometryResponse[0].scan_info.id);
            setPatientBiometry(patientBiometry);
          }
        }
      } catch (err: any) {
        if (err.detail) {
          addError(err.detail, 'error');
        } else {
          addError('Failed to load patient info', 'error');
        }
      }
      setLoading(false);
    })();
  }, [params, addError, setPatient])

  const EyeSumary = ({ side, biometry }: { side: string, biometry?: Biometry }) => (
    <>
      <Title>{eyeSideName(side)} <Typography component={'span'} sx={{
        fontWeight: 'light',
        textTransform: 'capitalize',
        fontSize: '0.9em'
      }}>{side} Eye</Typography></Title>

      <Typography variant="h5" sx={{ marginBottom: 1 }}>
        Biometry
      </Typography>
      <BiometryReport biometry={biometry} fullKeratometry={true} />
    </>
  )

  return (
    <>
      <PatientInfo patient={patient} loading={loading} />

      {!loading &&
        (
          <Grid container spacing={1} sx={{ maxWidth: LAYOUT_MAX_WIDTH * 2 }}>
            <Grid item xs={12} xl={6}>
              <EyeSumary side="right" biometry={patientBiometry?.at(1)} />
            </Grid>
            <Grid item xs={12} xl={6}>
              <EyeSumary side="left" biometry={patientBiometry?.at(0)} />
            </Grid>
          </Grid>
        )
      }
    </>
  )
}

export default IOLPlanner;
