import { Avatar, Link, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, ListSubheader, Typography } from "@mui/material";
import { ScanMetadata } from "../services/scan-service";


type ScanListProps = {
  scans: ScanMetadata[];
  selectedScanId?: string;
}

function ScanList(props: ScanListProps) {

  return (
    <List>
      <ListSubheader component="div" sx={{
        marginTop: '8px',
        marginBottom: '8px'
      }}>
        <Typography>Examinations</Typography>
      </ListSubheader>
      {props.scans.length ?
        props.scans.map(s => (
          <ListItem disablePadding key={s.scan_info.id} >
            <ListItemButton dense
              {...(s.scan_info.id !== props.selectedScanId ? {
                component: Link,
                to: `/scan/${s.scan_info.id}`,
              } : { sx: { color: 'lightgray' } })}>
              <ListItemAvatar>
                <Avatar sx={{ textTransform: 'uppercase' }}>{s.scan_info.eye[0]}</Avatar>
              </ListItemAvatar>
              <ListItemText primary={s.scan_info.timestamp.toISOString()} />
            </ListItemButton>
          </ListItem>
        )
        )
        : (
          <ListItem>
            <ListItemText primary={<Typography sx={{
              marginLeft: '4px',
              fontSize: '1em',
              color: 'darkgrey'
            }}>No more scans found ...</Typography>} />
          </ListItem>
        )}
    </List>
  )
}

export default ScanList;